<script setup lang="ts"></script>

<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_501_1826)">
            <path
                d="M15.8594 12.9688C14.7015 12.9688 13.6812 13.5383 13.0404 14.4045L7.49961 11.5673C7.5916 11.2538 7.65625 10.9288 7.65625 10.5859C7.65625 10.1209 7.5609 9.67871 7.39617 9.27203L13.1948 5.78266C13.8401 6.54 14.7886 7.03125 15.8594 7.03125C17.798 7.03125 19.375 5.45426 19.375 3.51562C19.375 1.57699 17.798 0 15.8594 0C13.9207 0 12.3438 1.57699 12.3438 3.51562C12.3438 3.96238 12.4357 4.38617 12.5884 4.77961L6.77246 8.27922C6.1277 7.54437 5.19285 7.07031 4.14062 7.07031C2.20199 7.07031 0.625 8.6473 0.625 10.5859C0.625 12.5246 2.20199 14.1016 4.14062 14.1016C5.31758 14.1016 6.35512 13.5153 6.99356 12.6248L12.5161 15.4527C12.4143 15.7811 12.3438 16.123 12.3438 16.4844C12.3438 18.423 13.9207 20 15.8594 20C17.798 20 19.375 18.423 19.375 16.4844C19.375 14.5457 17.798 12.9688 15.8594 12.9688Z"
                fill="url(#paint0_linear_501_1826)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_501_1826"
                x1="10"
                y1="0"
                x2="10"
                y2="20"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath id="clip0_501_1826">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
